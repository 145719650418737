<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">금칙어관리</h1>
    <!-- 조회창 시작 -->
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <v-form ref="form" v-model="isFormValid" lazy-validation>
        <div>
          <div class="form-inp sm">
            <v-select
                v-model.trim="formSearchData.ASP_NEWCUST_KEY"
                class="form-inp full"
                :items="userCompany"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                placeholder="선택하세요"
                required
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-text-field
                class="form-inp sm"
                name="name"
                label="금칙어명"
                id="id"
                outlined
                hide-details
                v-model="formSearchData.SEARCH_PROH_WORD"
                v-on:keyup.enter="result()"
            >
            </v-text-field>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
                :items="mixin_common_code_get(this.common_code, 'TWB001', '전체')"
                outlined
                hide-details
                placeholder="선택하세요"
                v-model="formSearchData.USE_YN"
            >
              <template v-slot:label>
                사용여부
              </template>
            </v-select>
          </div>
        </div>
      </v-form>
      </div>
      <div class="ml-auto align-self-center">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="result">조회</v-btn>
      </div>
    </div>
    <!-- 조회창 끝   -->

    <!-- 금칙어 리스트 시작-->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        금칙어현황
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" outlined class="btn-default" v-on:click="registWord" >신규</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDetail')" outlined class="btn-default ml-2" v-on:click="getWordView" >상세</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" v-on:click="showDeleteWordConfirm">삭제</v-btn>
        </div>
        <v-dialog
            v-if="dialogM810608P01"
            v-model="dialogM810608P01"
            max-width="655"
            :eager="true"
            content-class="square-modal min-auto"
        >
          <dialog-M810608P01
              :searchCustKey = "searchCustKey"
              headerTitle="금칙어 신규생성"
              @hide="hideDialog('M810608P01')"
              @hideAndGetList="hideAndGetList('M810608P01')"
          >
          </dialog-M810608P01>
        </v-dialog>
        <v-dialog
            v-if="dialogM810608P02"
            v-model="dialogM810608P02"
            max-width="655"
            :eager="true"
            content-class="square-modal min-auto"
        >
          <dialog-M810608P02
              :param="detailData"
              :searchCustKey = "searchCustKey"
              headerTitle="금칙어 상세"
              @hide="hideDialog('M810608P02')"
              @hideAndGetList="hideAndGetList('M810608P02')"
          >
          </dialog-M810608P02>
        </v-dialog>
      </h2>
      <div class="box-ct">
        <v-data-table
            show-select
            dense
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            item-key="PROH_WORD"
            :page.sync="page"
            :item-class="isActiveRow"
            hide-default-footer
            fixed-header
            class="grid-default text-truncate"
            height="500px"
            @page-count="pageCount = $event"
            v-on:dblclick:row="showModalInfo"
            v-on:click:row="setListSel"
            v-model="selectedCdList"
            no-data-text="검색된 결과가 없습니다."
        >
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption text-left">
          총
          <strong class="clr-error">{{ gridDataText.length }}</strong
          >건
        </p>
      </div>
    </div>
    <!--  금칙어관리 리스트 끝  -->
  </div>
</template>

<script>
import api from '@/store/apiUtil.js'
import DialogM810608P01 from "./M810608P01.vue"
import DialogM810608P02 from "./M810608P02.vue"
import { mixin } from "@/mixin/mixin.js"
import {mapGetters} from "vuex";

let jsonObj

export default {
  name: "MENU_M810608", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogM810608P01,
    DialogM810608P02
  },
  data: () => ({
    dialogM810608P01: false,
    dialogM810608P02: false,
    searchVo: function (){
      this.SEARCH_PROH_WORD = "" //금칙어명
      this.USE_YN = ""//사용여부
      this.ASP_NEWCUST_KEY = ""
    },
    formSearchData: {},
    common_code: [],
    selectedCdList: [],
    searchName: "",
    gridDataHeaders: [
      { text: "No", align: "center", value: "ROW_NUMBER", width: "50px", sortable: true },
      { text: "checkBox", value: "data-table-select", align: "center", width: "50px", sortable: true},
      { text: "금칙어", value: "PROH_WORD", align: "center", width: "150px", sortable: true },
      { text: "변환어", value: "CHANGE_WORD", align: "center", width: "150px", sortable: true },
      { text: "등록자ID", value: "REGR_ID", align: "center", width: "100px", sortable: true },
      { text: "등록자명", value: "REGR_NAME", align: "center", width: "100px", sortable: true },
      { text: "등록일자", value: "REGR_DTTM", align: "center", width: "200px", sortable: true },
      { text: "수정자ID", value: "AMDR_ID", align: "center", width: "100px", sortable: true },
      { text: "수정자명", value: "AMDR_NAME", align: "center", width: "100px", sortable: true },
      { text: "수정일자", value: "UPD_DTTM", align: "center", width: "200px", sortable: true },
      { text: "사용여부", value: "USE_YN", align: "center", width: "100px", sortable: true }
    ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    totalVisible: 10,
    detailData: {},
    selectedRow: [],
    selectedRow2: [],
    isFormValid: true,
    searchCustKey: ''
  }),
  mounted() {
    this.initData()
  },
  methods: {
    initData() {

      this.callCommonCode()

      this.detailData = ""
      this.$refs.form.resetValidation()
      this.formSearchData = new this.searchVo()
    },
    async callCommonCode(){
      let codeName = ["TWB001"]
      this.common_code= await this.mixin_common_code_get_all(codeName)
    },
    showModalInfo(event, { item }) {
      this.detailData = {}
      this.detailData = item
      this.showDialog("M810608P02");
    },
    setListSel(item, row) {
      this.detailData = item;
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    result() {
      if(this.isEmpty(this.formSearchData.ASP_NEWCUST_KEY)){
        this.common_alert('회사구분 은(는) 필수입력입니다.', 'error')
        return false
      }
      if(!this.$refs.form.validate()) return false
      this.apiCall('L', this.formSearchData)
    },
    setGridText(jsonData) {
      jsonObj = JSON.parse(jsonData);
      this.gridDataText = jsonObj;
    },
    showDeleteWordConfirm(){
      if(this.isEmpty(this.selectedCdList)){
        this.common_alert('체크된 항목이 존재하지 않습니다.', 'error')
        return;
      }
      this.common_confirm('해당 금칙어정보를 삭제하시겠습니까?', this.deleteWord, '', this.closeAlert, '', 'error')
    },
    deleteWord(){

      let deleteList = [];

      for(let i in this.selectedCdList){
        let obj = {
          ASP_NEWCUST_KEY: this.selectedCdList[i].ASP_NEWCUST_KEY,
          PROH_CODE: this.selectedCdList[i].PROH_CODE
        };
        deleteList.push(obj)
      }

      let jsonData = {
        sendData: {
          DATA: deleteList
        }
      };

      this.apiCall('D', jsonData.sendData)
    },
    async apiCall(type, param){

      let URLData = ""
      let HEADER_SERVICE = ""
      let HEADER_TYPE = "BIZ_SERVICE"
      let HEADER_METHOD = ""
      let HEADER_ROW_CNT = 1000;
      let HEADER_PAGES_CNT = 1;

      if(type === 'D'){
        URLData = "/api/chat/setting/banned-word/delete"
        HEADER_SERVICE = "chat.setting.banned-word"
        HEADER_METHOD = "delete"

      }else{
        URLData = "/api/chat/setting/banned-word/list"
        HEADER_SERVICE = "chat.setting.banned-word"
        HEADER_METHOD = "list"
      }

      await api
          .post(URLData, param, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
              ROW_CNT: HEADER_ROW_CNT,
              PAGES_CNT: HEADER_PAGES_CNT
            },
          })
          .then((response) => {
            if(type === 'D'){
              //삭제
              if(!this.isEmpty(response) && !response.data.HEADER.ERROR_FLAG) {
                this.common_alert('정상처리 되었습니다.', 'done')
                this.selectedRow = [];
                this.detailData = {}
                this.result()
              } else {
                this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
              }
            }else{
              if(!this.isEmpty(this.selectedRow)){
                //초기화
                this.selectedRow = [];
                this.detailData = {}
              }
              //조회
              this.searchCustKey = this.formSearchData.ASP_NEWCUST_KEY
              this.setGridText(JSON.stringify(response.data.DATA));
            }
          })
          .catch((err) => {
            alert(err);
          });

    },
    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && (''+val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val) < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
      if (val.constructor === Object && Object.keys(val).length === 0) return true
    },
    closeAlert(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    registWord(){
      if(this.isEmpty(this.formSearchData.ASP_NEWCUST_KEY)){
        this.common_alert('회사구분은(는) 필수 입력입니다.', 'error')
        return false
      }
      this.showDialog("M810608P01")
    },
    hideAndGetList(type){
      this.detailData = {}
      this.selectedCdList = []
      this.hideDialog(type)
      this.result()
    },
    getWordView(){
      if(this.isEmpty(this.detailData)){
        this.common_alert('선택된 항목이 존재하지 않습니다.', 'error')
        return;
      }
      this.showDialog("M810608P02")
    },
    isActiveRow(item){
      return item.PROH_WORD == this.detailData.PROH_WORD? 'active':'';
    }
  },
  computed: {
    ...mapGetters({
      userCompany: 'userStore/GE_USER_COMPANY'
    }),
  }
};
</script>
<style>


</style>
